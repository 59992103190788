// @ts-ignore

import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { Breakpoints } from './interfaces';

export const findIndexInArray = (originalArray, objectToFInd, key, key2?: string | undefined) => {
  return originalArray.findIndex((item) => {
    if (key2) return item[key][key2] === objectToFInd[key][key2];
    else return item[key] === objectToFInd[key];
  });
};

export const returnUpdatedList = (newObj, oldList, key = 'id', key2 = '') => {
  const indexOfLocal = findIndexInArray(oldList, newObj, key, key2);
  if (indexOfLocal !== -1) oldList[indexOfLocal] = newObj;
  return oldList;
};

export const removeDuplicates = (myArr: any[], prop: any) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

// blocks invalid character in number input type and disables number field controller
export const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

//rounds up a number(value) to x decimal places
// it returns a whole number when value is whole
export const roundUp = (value: number, x: number) => {
  const multiplier = Math.pow(10, x || 0);
  return Math.round(value * multiplier) / multiplier;
};

// function to add st, nd, rd, th to positions
export const postion = (number: number) => {
  const lastDigit = number % 10,
    k = number % 100;

  if (lastDigit === 1 && k !== 11) {
    return number + 'st';
  } else if (lastDigit === 2 && k !== 12) {
    return number + 'nd';
  } else if (lastDigit === 3 && k !== 13) {
    return number + 'rd';
  } else {
    return number + 'th';
  }
};

export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export function waitForDomElement(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
  // Usage
  // const help_button = await waitForDomElement('#help-btn');
}

export const isEmpty = (value: any) => {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
};

export const calculateDatePercentage = (startDate: Date, endDate: Date): number => {
  const now = new Date().getTime();
  const start = startDate.getTime();
  const end = endDate.getTime();
  const range = end - start;
  const val = Math.ceil((100 * (now - start)) / range);
  return val > 100 ? 100 : 0 > val ? 0 : val;
};

export const breakpoints: Breakpoints = {
  base: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1536,
};

export const formatDate = (dateType: Date | string) => {
  const date = moment(dateType);
  const now = moment();
  if (now.diff(date, 'days') < 7) {
    return date.fromNow(); // Output: a week ago
  } else if (now.diff(date, 'months') < 1) {
    return date.fromNow(); // Output: 1 week ago
  } else {
    const formattedDate = date.format('h:mm A, MMMM D');
    return formattedDate;
  }
};

export const renderNewColor = () => {
  const colorArray = [
    '#f26522',
    '#02b176',
    '#000000',
    '#7e7e7e',
    '#DD9787',
    '#3DCCC7',
    '#E75A7C',
    '#632A50',
    '#DE6449',
    '#745296',
  ];
  const chosenColor = Math.floor(Math.random() * colorArray.length);
  return colorArray[chosenColor];
};

export const linkify = (inputText) => {
  //URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>',
  );

  //Change email addresses to mailto:: links.
  const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const Notify = NotificationManager;

export const getInitials = (name: string) => {
  const username = name?.split(' ');
  const initials = username?.map((name) => name?.charAt(0));
  return initials?.join('');
};

export const getSecondsToHoursMinAndSecs = (seconds: number) => {
  const hour = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const min = Math.floor(remainingSeconds / 60);
  const sec = remainingSeconds % 60;

  const hourWithZeroPrefix = hour < 10 ? `0${hour}` : hour;
  const minWithZeroPrefix = min < 10 ? `0${min}` : min;
  const secWithZeroPrefix = sec < 10 ? `0${sec}` : sec;

  return {
    hour,
    min,
    sec,
    hourWithZeroPrefix,
    minWithZeroPrefix,
    secWithZeroPrefix,
  };
};

export function generateRandomCode() {
  const digits = '0123456789';
  let reference = '';

  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    reference += digits[randomIndex];
  }

  return reference;
}

export const getFormattedDate = (response: string | Date, format = 'Do MMM YYYY') => {
  return response ? moment(response).format(format) : '';
};

export const splitCamelCase = (inputString: string) => {
  if (!inputString) return '';

  const splitString = inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
  const capitalizedString = splitString.replace(/\b\w/g, (char) => char.toUpperCase());

  return capitalizedString;
};

export const getUpdatedFieldsFromObject = (initialValues, updatedValues) => {
  const updatedFields = {};

  // Loop through updatedValues to check for updates and new keys
  for (const key in updatedValues) {
    if (initialValues[key] !== updatedValues[key]) {
      updatedFields[key] = updatedValues[key];
    }
  }

  return updatedFields;
};
export const getPdfName = (str, title) => {
  const keyword = 'publications/';
  const index = str.indexOf(keyword);

  if (index === -1) {
    return `${title}.pdf`;
  }

  const documentName = str.substring(index + keyword.length);

  return documentName;
};

export const getFormattedTime = (response, format = 'H:mm a') => {
  return response ? moment(response).format(format) : '';
};

export const getExternalLink = (str: string) => {
  if (str.startsWith('http')) {
    return str;
  } else return 'https://' + str;
};

export const onlyNumbers = (e: any) => {
  const num = /[^0-9]/gi;
  e.target.value = e.target.value.replace(num, '');
};

export const countWords = (str: string) => {
  if (!str) return 0;
  return str.trim().split(/\s+/).length;
};

export const stripHtmlTags = (input: string): string => {
  const htmlTagPattern = /<\/?[^>]+(>|$)/g;
  return input.replace(htmlTagPattern, '');
};

export const today = new Date();
export const getStartOfDay = (date) => {
  return moment(date).utc().startOf('day').toISOString();
};
export const getEndOfDay = (date) => {
  return moment(date).utc().endOf('day').toISOString();
};
