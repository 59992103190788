import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import PrivilegesTypes from './types';

export type IAppPrivilegeState = {
  view_member_diary: boolean;
  update_member_diary: boolean;
  view_member_chat: boolean;
  create_member_chat: boolean;
  view_member_appointments: boolean;
  view_all_members: boolean;
  view_member_notes: boolean;
  view_member_interactions: boolean;
  view_member_activity: boolean;
  create_member_notes: boolean;
  create_member_interactions: boolean;
  create_member_appointment: boolean;
  view_all_subscriptions: boolean;
  view_member_subscription: boolean;
  view_program_subscriptions: boolean;
  approve_program: boolean;
  approve_referrals: boolean;
  approve_voucher: boolean;
  approve_grammar: boolean;
  assign_role: boolean;
  view_roles: boolean;
  create_medication: boolean;
  view_medication: boolean;
  create_symptom: boolean;
  view_symptom: boolean;
  create_specialty: boolean;
  view_specialty: boolean;
  create_disease: boolean;
  view_disease: boolean;
  create_program: boolean;
  view_all_programs: boolean;
  view_program: boolean;
  edit_program: boolean;
  delete_program: boolean;
  create_bla: boolean;
  view_bla: boolean;
  add_program_coaches: boolean;
  create_voucher: boolean;
  view_voucher: boolean;
  invite_coach: boolean;
  view_all_coaches: boolean;
  view_coach: boolean;
  edit_coach: boolean;
  delete_coach: boolean;
  assign_coach_members: boolean;
  view_report: boolean;

  delete_publication_post: boolean;
  create_publication_post: boolean;
  view_publication_post: boolean;
  create_webinar_post: boolean;
  view_webinar_post: boolean;
  create_blog_post: boolean;
  view_blog_post: boolean;
  delete_blog_post: boolean;
  view_career: boolean;
  delete_career: boolean;
  create_career: boolean;
  record_offline_payment: boolean;
  view_offline_payment: boolean;
  view_outliers: boolean;
  view_hubvisit: boolean;
  create_hub: boolean;
  view_payments: boolean;
};

export const INITIAL_STATE = {
  view_member_diary: false,
  update_member_diary: false,
  view_member_chat: false,
  create_member_chat: false,
  view_member_appointments: false,
  view_all_members: false,
  view_member_notes: false,
  view_member_interactions: false,
  view_member_activity: false,
  create_member_notes: false,
  create_member_interactions: false,
  create_member_appointment: false,
  view_all_subscriptions: false,
  view_member_subscription: false,
  view_program_subscriptions: false,
  approve_program: false,
  approve_referrals: false,
  approve_voucher: false,
  approve_grammar: false,
  assign_role: false,
  view_roles: false,
  create_medication: false,
  view_medication: false,
  create_symptom: false,
  view_symptom: false,
  create_specialty: false,
  view_specialty: false,
  create_disease: false,
  view_disease: false,
  create_program: false,
  view_all_programs: false,
  view_program: false,
  edit_program: false,
  delete_program: false,
  create_bla: false,
  view_bla: false,
  add_program_coaches: false,
  create_voucher: false,
  view_voucher: false,
  invite_coach: false,
  view_all_coaches: false,
  view_coach: false,
  edit_coach: false,
  delete_coach: false,
  assign_coach_members: false,
  view_report: false,

  delete_publication_post: false,
  create_publication_post: false,
  view_publication_post: false,

  create_webinar_post: false,
  view_webinar_post: false,

  create_blog_post: false,
  view_blog_post: false,
  delete_blog_post: false,

  view_career: false,
  delete_career: false,
  create_career: false,

  record_offline_payment: false,
  view_offline_payment: false,
  view_outliers: false,

  view_hubvisit: false,
  create_hub: false,
  view_payments: false,
};

function privilegeReducer(state: IAppPrivilegeState = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case PrivilegesTypes.UPDATE_APP_PRIVILEGES:
      return {
        ...state,
        ...payload,
      };
    case PrivilegesTypes.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}

const persistConfig = {
  key: 'privilges',
  storage,
  blacklist: ['error', 'isLoading', 'loading'],
};

export default persistReducer(persistConfig, privilegeReducer);
