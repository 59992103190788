import { NotificationManager } from 'react-notifications';

interface IObj {
  code: number;
  message: string;
}

export const handleError = (err: any, obj?: IObj | IObj[], show = true, override401 = false) => {
  // obj = { code: 404, message: "An error occured" } ||
  //  [{ code: 404, message: "An error occured" }]
  console.log({ err });
  window.process = { ...window.process };
  if (process) {
    const NODE_ENV = process.env.NODE_ENV;
    if (NODE_ENV === 'development') {
      console.error(err);
    }
  }
  // eslint-disable-next-line prefer-const
  let { response, body, status } = err;
  body = body?.error || body;
  if (body && !response) {
    response = {
      data: body,
      status: body.status || body.statusCode || status,
    };
  }
  let msg;
  let statusCode: number;
  const constMessage =
    'Sorry, an error has occurred, Please try again or if issue persist, contact support.';
  const msgObj = {
    '404': "We can't find the resource you are looking for.",
    '422': 'Bad entry, please crosscheck your data.',
    '400': 'Sorry, an unexpected error occurred. Please try again.',
    '600': 'Sorry, an error occurred. Please check your internet conection and try again.',
    '500':
      'Sorry Something went wrong. We have logged this error and our engineers are working to fix it as soon as possible. If you need immediate assistance, please contact our support.',
    '401': '',
    '403':
      'Sorry, You do not have a permission to access the document or program that you requested',
    '408': 'Sorry, your request took too long to process, please try again.',
    '502':
      "Sorry, we are currently experiencing a glitch with this service. Don't worry we are already aware and service will be restored as soon as possible. If you need immediate assistance, please contact our support.",
    '503':
      "Sorry, we are currently experiencing a glitch with this service. Don't worry we are already aware and service will be restored as soon as possible. If you need immediate assistance, please contact our support.",
    '504': 'Sorry, your request took too long to process, please try again.',
  };
  if (response && response instanceof Object) {
    statusCode = response?.status;
    const { data } = response;
    msg = data.message || data.error || constMessage;
    // Might fallback to this.
    // msgObj[`${statusCode}`] = msg;
  } else if (err?.name === 'ApiError') {
    statusCode = err?.status;
    msg = err?.body?.message || err?.body?.error || constMessage;
  } else {
    statusCode = 600;
  }
  if (statusCode === 409) {
    msgObj[`${statusCode}`] = msg;
  }
  if (!msgObj[`${statusCode}`]) {
    msgObj[`${statusCode}`] = constMessage;
  }
  if (obj instanceof Object) {
    // @ts-ignore
    msgObj[`${obj.code}`] = obj.message;
  }
  if (obj instanceof Array) {
    obj.forEach((x) => {
      msgObj[`${x.code}`] = x.message;
    });
  }
  if (statusCode === 401 && msgObj['401'] === '') {
    return;
  }
  if (show && (statusCode !== 401 || override401)) {
    NotificationManager.error(msgObj[statusCode]);
  }
  return { success: false, statusCode, message: msg };
};
